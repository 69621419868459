@import 'src/common/styles/_colors.scss';


.settings-loading {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 780px;
  .spinner-wrapper {
    position: absolute;
    background-color: $gray-light;
    opacity: .8;
    border: 2px solid $gray-lightest;
    border-radius: 25px;
  }
}
