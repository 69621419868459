@import 'src/common/styles/_colors.scss';


.page-not-found {
    h1 {
      font-size: 170px;
    }
    button:hover {
      color: $yellow;
    }
}
