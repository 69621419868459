@import 'src/common/styles/_colors.scss';


.admin-page {
  width: 100%;
  height: 100vh;
  overflow: scroll;
  .navbar {
    .admin-title {
      padding-top: 0;
      h1 {
        color: $gray;
        font-weight: 300;
        font-size: 30px;
        span {
          color: $primary-blue;
          font-weight: 800;
          letter-spacing: 1px;
          margin-left: 6px;
        }
      }
    }
  }
  .admin-content {
    width: 100%;
    min-height: calc(100vh - 125px);
  }
  .admin-footer {
    text-align: center;
    font-size: 14px;
    color: $gray;
    margin-top: 0px;
    padding-bottom: 20px;
    img {
      padding-left: 5px;
      height: 30px;
    }
  }
  .toast {
    position: absolute;
    bottom: 30px;
    right: 30px;
    min-width: 300px;
    background-color: $white;
  }
  @media(max-width: 991px) {
    .navbar {
      .admin-title {
        font-size: 18px;
        line-height: 25px;
      }
    }
  }
  @media(max-width: 576px) {
    .navbar {
      .admin-title {
        font-size: 14px;
        line-height: 15px;
      }
    }
  }
}
