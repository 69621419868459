@import 'src/common/styles/_colors.scss';

.email-field-setting {
  .input-container {
    display: inline-block;
    margin-right: 8px;
    min-width: 250px;
  }
  .input-group-append {
    display: inline-block;
    box-shadow: 1px 2px 4px rgba(0, 0, 0, .3);
    background-color: $primary-blue;
    border-radius: 50%;
    padding: 8px 13px;
    transition: all 0.3s ease-in-out;
    opacity: .85;
    .add-icon {
      font-size: 17px;
      color: $white;
      transition: all 0.3s ease-in-out;
    }
    &:hover {
      cursor: pointer;
      opacity: 1;
    }
  }
  .email-field-list {
    padding-top: 5px;
    .email-address {
      color: $white;
      background-color: $primary-blue;
      padding: 8px 15px;
      border-radius: 20px;
      font-size: 14px;
      margin-bottom: 5px;
      display: inline-block;
      margin-right: 10px;
    }
  }
  .email-error {
    color: $red;
    position: absolute;
    right: 150px;
    top: 5px;
    font-size: 14px;
  }
  @media (max-width: 1200px) {
    .input-container {
      margin-right: 0px;
      .form-control {
        padding-right: 45px;
      }
    }
    .input-group-append {
      position: absolute;
      right: 10px;
      margin-top: -1px;
    }
    .email-error {
      right: 115px;
    }
  }
  @media (max-width: 767px) {
    .input-container {
      margin-top: 8px;
      min-width: 200px;
      .form-control {
        font-size: 12px;
      }
    }
    .input-group-append {
      padding: 4px 9px;
      margin-top: 7px;
    }
    .email-field-list {
      .email-address {
        font-size: 12px;
      }
    }
    .email-error {
      right: 85px;
      top: 10px;
      font-size: 12px;
    }
  }
}
