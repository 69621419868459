@import 'src/common/styles/_colors.scss';


.admin-page .admin-title {
  display: flex;
  align-items: center;
  font-weight: 900;
  font-size: 28px;
  .guard-logo {
    height: 30px;
    padding-right: 5px;
  }
  .admin-title-first.guard-title {
    color: $guard-red;
    font-weight: 900;
  }
  .admin-title-remaining.guard-title {
    color: $gray;
    font-weight: 300;
  }
}

.guard-dashboard {
  .date-range {
    font-size: 13px;
    color: $gray;
    font-weight: 600;
    margin: 0;
    padding-bottom: 5px;
  }
  .circle-buttons {
    display: flex;
    float: right;
    .button {
      margin: 0 10px;
      color: $guard-red;
      font-size: 14px;
      &:hover:not(.selected) {
        color: $white;
        background-color: $red;
      }
      &.selected {
        color: $white;
        background-color: $guard-red;
      }
    }
  }
  .panel-row {
    .email-count-panel, .incident-panel {
      padding-left: 0;
      padding-right: 0;
    }
  }
  .incident-graph {
    min-height: 570px;
    width: 100%;
    border: 1.5px solid $gray-light;
    border-radius: 16px;
    padding: 20px;
    margin-bottom: 20px;
    .incident-graph-controls {
      display: flex;
      align-items: center;
      svg {
        color: $guard-red;
      }
      .incident-graph-logo {
        display: inline-block;
        margin-right: 15px;
      }
      .graph-select {
        display: inline-block;
        width: 225px;
      }
    }
    .spinner-wrapper {
      margin-top: 175px;
      color: $guard-red;
    }
  }
  .settings-button {
    .button {
      cursor: pointer;
      color: $gray-light;
      padding-top: 4px;
    }
  }
}

.modal-dialog.incident-modal {
  font-family: 'Lato';
  .incident-modal-title {
    font-size: 35px;
    color: $gray;
    .incident-modal-title-name {
      color: $red;
    }
  }
  .incident-modal-email {
    font-size: 20px;
    color: $gray;
  }
  .legend-box {
    width: 22px;
    height: 17px;
    float: left;
    @media (max-width: 991px) {
      width: 14px;
      height: 14px;
    }
  }
  .legend-label {
    font-size: 16px;
    @media (max-width: 991px) {
      font-size: 14px;
    }
  }
  .total-risks {
    font-size: 12px;
    .total-risk-count {
      color: $red;
      font-size: 30px;
    }
  }
}

.modal-dialog.settings-modal {
  width: 350px;
  margin: 1.75rem auto;
  .modal-header {
    border: 0;
    padding-bottom: 0;
    .modal-title {
      font-family: 'Lato';
      font-size: 18px;
    }
  }
}

.modal-dialog.stat-panel-modal {
  max-width: 1000px;
  .modal-header .modal-title {
    font-family: 'Lato';
    line-height: 1.2;
    svg {
      vertical-align: top;
      margin: 5px 10px 0 0;
    }
    .incident-panel-modal-title {
      display: inline-block;
      font-size: 40px;
      .incident-panel-modal-subtitle {
        font-size: 18px;
        font-weight: 300;
        color: $gray;
      }
    }
  }
  .modal-body {
    .guard-email-table {
      min-height: 200px;
    }
    .total-incidents {
      margin-top: 30px;
      font-size: 12px;
      .total-incident-count {
        font-size: 30px;
      }
    }
  }
}

.guard-email-table.data-table {
  max-height: 500px;
  table {
    th {
      background-color: $red;
      &:hover {
        background-color: $guard-red;
      }
    }
    td + td {
      border-left: 2px solid $gray-light;
    }
    tr {
      background-color: $white;
    }
    tr:nth-child(odd) {
      background-color: $gray-lightest;
    }
  }
}
