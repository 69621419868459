@import 'src/common/styles/_colors.scss';

.custom-dropdown {
    .custom__control {
      &:hover {
          cursor: pointer;
      }
    }
    .custom__menu {
        .custom__option{
            &:hover {
                background-color: $button-background;
                color: $almost-black;
                cursor: pointer;
            }
        }
        .custom__option--is-focused {
            background-color: $white;
            color: $almost-black;
        }
        .custom__option--is-selected {
            background-color: $primary-blue;
            color: $white;
        }
    }
}
