@import 'src/common/styles/_colors.scss';

.out-of-office-setting {
  transition: all 0.3s ease-in-out;
  .btn {
    border: none;
    border-radius: 50%;
    padding: 10px 15px;
    position: relative;
    background-color: $primary-blue;
    color: $white;
    box-shadow: 1px 2px 4px rgba(0, 0, 0, .3);
    opacity: .85;
    transition: all 0.3s ease-in-out;
    &:hover {
      color: $white;
      opacity: 1;
    }
  }
  .range-text {
    width: fit-content;
    background-color: $primary-blue;
    color: $white;
    padding: 7px 12px;
    border-radius: 30px;
    font-size: 15px;
  }
  .calendar-container {
    position: absolute;
    z-index: 1;
    top: 70px;
    right: 15px;
    .range-calendar {
      box-shadow: 2px 5px 10px 0px rgba(0,0,0,0.3);
    }
  }
  @media(max-width: 767px) {
    .btn {
      font-size: 15px;
      padding: 8px 13px;
    }
    .range-text {
      font-size: 12px;
    }
  }
}
