.dropdown-setting {
    width: 100%;
    .custom-dropdown {
        width: 250px;
        font-size: 14px;
        margin-left: auto;
    }
    @media(max-width: 767px) {
        .custom-dropdown {
            width: 180px;
            font-size: 12px;
        }
    }
}
