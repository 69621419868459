.login-form {
  margin-top: 20vh;
  border: 3px black;
  .login-logo img {
    max-width: 400px;
    padding: 25px 0 50px 0;
  }
  .login-form-error {
    margin-top: 10px;
    text-align: center;
  }
  .btn.login {
    background-color: #E25E04;
    border: none;
    &:hover {
      background-color: #F2680A;
      border: none;
    }
  }
}
