@import 'src/common/styles/_colors.scss';

.donor-journey-legend {
  position: sticky;
  top: 10px;
  background-color: $gray-lightest;
  border: 1px solid $gray-light;
  border-radius: 10px;
  padding: 10px 5px;
  width: fit-content;
  margin: 0 auto 15px auto;
  font-size: 12px;
  img {
    height: 25px;
    width: 25px;
    margin-right: 5px;
  }
}
