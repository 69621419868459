@import 'src/common/styles/_colors.scss';


.health-bar {
  .title {
    font-family: 'Lato';
    font-size: 8px;
    padding-left: 5px;
  }
  .progress {
    height: 25px;
    width: 250px;
    border: 1px solid $gray-light;
    border-radius: 12px;
    background-color: $white;
    .progress-bar {
      height: 25px;
      background-color: $red;
      &.healthy {
        background-color: $green;
      }
      border-radius: 12px;
      margin-top: -1px;
    }
    .progress-bar-text-left, .progress-bar-text-right {
      font-family: 'Lato';
      font-weight: 700;
      font-size: 12px;
      align-self: center;
    }
    .progress-bar-text-right {
      flex: 1;
      text-align: center;
      color: $red;
    }
  }
}
