@import "src/common/styles/_colors.scss";


.portfolio-pages {
  min-height: 265px;
  .page-button {
    &:hover {
      text-decoration: none;
    }
    button {
      position: relative;
      padding: 0px;
      margin-bottom: 5px;
      transition: all 0.3s ease-in-out;
      border-radius: 50%;
      width: 130px;
      height: 130px;
      background-color: $primary-blue;
      opacity: .85;
      &:hover {
        opacity: 1;
        transform: scale(1.1);
      }
      img {
        transition: all 0.3s ease-in-out;
        width: 75px;
        margin: auto;
      }
      .icon {
        transition: all 0.3s ease-in-out;
        font-size: 60px;
        color: $white;
      }
    }
    .button-title {
      font-size: 14px;
      letter-spacing: 1px;
      font-weight: 900;
      display: block;
      width: 160px;
      margin-left: auto;
      margin-right: auto;
      color: $blue-dark;
      transition: all 0.3s ease-in-out;
      font-family: 'Lato';
    }
    .no-buttons-error {
      color: $primary-blue;
      font-size: 18px;
    }
    @media (max-width: 991px) {
      button {
        width: 90px;
        height: 90px;
        img {
          width: 50px;
        }
        .icon {
          font-size: 40px;
        }
      }
    }
  }
}
