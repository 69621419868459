@import 'src/common/styles/_colors.scss';

.delete-button {
  color: $white;
  cursor: pointer;
  font-size: 20px;
  transition: all 0.3s ease-in-out;
  opacity: .9;
  line-height: 0;
  &:hover {
    opacity: 1;
  }
  .fa-times-circle {
    background-color: $primary-blue;
    border-radius: 50%;
    vertical-align: middle;
  }
}
@media (max-width: 767px) {
  .delete-button {
    font-size: 18px;
  }
}
