@import 'src/common/styles/_colors.scss';

.save-bar {
  background-color: $gray-light;
  position: absolute;
  bottom: 0;
  left: 0;
  max-height: 0;
  width: 100%;
  line-height: 65px;
  padding-left: 40px;
  border-top: 1px solid $gray;
  transition: max-height 0.2s ease-out;
  overflow: hidden;
  .btn {
    margin-left: 10px;
    margin-right: 10px;
    border: 1px solid $primary-blue;
    opacity: .8;
    transition: all 0.3s ease-in-out;
    &:hover {
      opacity: 1;
    }
  }
  .btn.save {
    background-color: $primary-blue;
    color: $white;
  }
  .btn.cancel {
    background-color: $white;
    color: $primary-blue;
  }
  &.show-bar {
    max-height: 65px;
    transition: max-height 0.2s ease-in;
  }
}
