@import 'src/common/styles/_colors.scss';


.week {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 280px;
  border: 1px solid $black;
  border-radius: 5px;
  padding: 10px;
  margin: 5px;

  &.add-button {
    display: block;
    min-height: 200px;
    padding: 0;
    svg {
      height: 100%;
    }
  }

  .week-title {
    margin-top: -10px;
    height: 50px;
    line-height: 50px;
    text-align: center;
  }

  hr {
    margin: 0;
  }

  ul {
    margin: 0;
    padding-left: 25px
  }

  .fd-input {
    margin: 5px;
    &:focus {
      outline: none;
    }
    input, select {
      display: inline-block;
      width: 85px;
      margin: 5px;
    }
  }
  .fd-read-only {
    cursor: pointer;
    margin-top: 10px;
    border: 1px solid transparent;
    &:hover {
      border: 1px dashed $gray-light;
      border-radius: 5px;
    }
  }

  .week-remove, .week-copy {
    cursor: pointer;
    position: absolute;
    top: 10px;
  }
  .week-remove {
    right: 10px;
  }
  .week-copy {
    right: 30px;
  }
}
