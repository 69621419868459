@import 'src/common/styles/_colors.scss';


.collapse-text {
  cursor: pointer;
  margin-bottom: 10px;
  transition: all 0.3s ease-in-out;
  .collapse-text-button {
    font-size: 12px;
    margin-left: 5px;
    svg {
      color: $gray;
      margin-right: 2px;
    }
  }
  .collapse-text-text {
    margin-left: 10px;
  }
}
