@import 'src/common/styles/_colors.scss';


html, body, #root {
  min-height: 100%;
}

.page-container {
  position: relative;
  min-height: 100vh;
  width: 100%;
  font-family: 'Open Sans';
  background-color: $white;

}
