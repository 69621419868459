.spinner-wrapper {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  &.large-spinner {
    font-size: 5em;
  }
}
