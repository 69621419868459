@import 'src/common/styles/_colors.scss';


.hideable-select {
  .hideable-select-control {
    font-size: 12px;
    font-weight: normal;
    text-decoration: underline;
  }
  .hideable-select-input {
    color: $black;
    overflow: visible;
    opacity: 1;
    // browsers can't transition to 'auto', so transition to a max height
    // and the height will be set based on auto
    height: auto;
    max-height: 100px;
    transition: max-height 0.25s linear, opacity 0.5s linear;
    &.hide {
      overflow: hidden;
      opacity: 0;
      max-height: 0;
    }
    .hideable-select-footer {
      color: $white;
    }
  }
}
