@import 'src/common/styles/_colors.scss';


h1 {
  color: $black;
}

h2 {
  color: $blue-dark;
}

h3 {
  color: $blue;
}
