@import 'src/common/styles/_colors.scss';


.data-table {
  overflow: auto;
  table {
    th, td {
      padding: 0 5px;
    }
    th {
      position: sticky;
      top: 0;
      vertical-align: top;
      background-color: $blue;
      color: white;
      border: 2px solid #F5F5F5;
      white-space: nowrap;
      padding: 5px 10px;
      cursor: pointer;
      &:hover {
        background-color: $blue-dark;
      }
      min-width: 225px;
      .column-title-row {
        min-width: max-content;
        .column-title {
          svg {
            margin-left: 5px;

            &.fa-sort-up {
              vertical-align: bottom;
            }
            &.fa-sort-down {
              vertical-align: top;
            }
          }
        }
        .pie-chart-button-wrapper {
          padding-left: 5px;
          .pie-chart-button {
            padding: 0 4px;
            &:hover {
              background-color: $yellow;
              border-color: $yellow;
              svg {
                color: $white;
              }
            }
          }
        }
      }
      .hideable-select-control {
        width: fit-content;
        &:hover {
          color: $yellow;
        }
      }
      .react-select-container {
        color: $black;
        .react-select__control {
          .react-select__value-container {
            padding: 0;
          }
          .react-select__indicator {
            padding: 0;
          }
        }
        .react-select__menu {
          width: auto;
        }
      }
    }
    td + td {
      border-left: 1px solid $blue;
    }
    tr {
      background-color: $white;
    }
    tr:nth-child(odd) {
      background-color: $blue-light;
    }
  }
}
