.bar-graph {
  button {
    position: absolute;
    top: -20px;
    left: -20px;
    padding: 1px 5px;
    font-size: 14px;
    line-height: 1.5;
    border-radius: 3px;
  }
}
