@import 'src/common/styles/_colors.scss';


.settings-tip.tooltip {
  .tooltip-inner {
    padding: 15px 15px 5px 15px;
    h5 {
      font-size: 16px;
    }
    p {
      font-size: 12px;
    }
  }
}

.settings-controls {
  .row {
    background-color: $gray-lightest;
    padding: 15px 10px;
    border-radius: 15px;
    min-height: 65px;
    position: relative;
    .incident-tip {
      position: absolute;
      top: -10px;
      right: -5px;
      color: $gray;
      transition: all 0.3s;
      &:hover {
        color: $guard-red;
        cursor: pointer;
      }
    }
  }
  .setting-icon {
    display: flex;
    width: 32px;
    height: 27px;
    background-color: $guard-red;
    color: $white;
    svg {
      display: block;
      margin: auto;
    }
  }
  .setting-title {
    font-family: 'Lato';
    font-size: 11px;
    padding-left: 10px;
  }
  .setting-checkboxes {
    display: flex;
    flex-wrap: wrap;
  }
  .settings-checkbox {
    margin-right: 10px;
    // hide the default checkbox for the input and replace it with
    // a checkbox drawn after the label
    input[type=checkbox] {
      display: none;
    }
    label {
      cursor: pointer;
      margin-right: 7px;
      font-family: 'Lato';
      font-size: 12px;
      font-weight: 700;
    }
    .settings-checkbox-input {
      cursor: pointer;
      display: inline-block;
      background-color: $white;
      width: 13px;
      height: 13px;
      border-radius: 4px;
      box-shadow: 0px 1.12681px 1.69021px rgba(0, 0, 0, 0.25);
      transition: all .5s;
      transform: translateY(1px);
    }
  }
  input[type=range].range-slider::-webkit-slider-runnable-track {
    height: 13px;
    background: $gray-lightest;
    background-image: url('https://gravyty.s3.amazonaws.com/gravyty_guard/slider-background.png');
    background-repeat: round;

  }
  input[type=range].range-slider.range-slider--primary::-webkit-slider-thumb {
    transform: translateY(4px);
    background: #1E1E24;
  }
  .toggle-input {
    position: relative;
    cursor: pointer;
    background-color: $guard-red;
    opacity: 0.5;
    height: 30px;
    width: 75px;
    transition: all 0.3s ease-in-out;
    .toggle {
      position: absolute;
      left: 0;
      background-color: $white;
      height: 22px;
      width: 22px;
      margin: 4px;
      transition: all 0.3s ease-in-out;
    }
    &.toggle-enabled {
      background-color: $guard-red;
      opacity: 1;
      .toggle {
        transform: translateX(45px);
      }
    }
  }
}
