@import 'src/common/styles/_colors.scss';


.dashboard-report {
  margin-bottom: 20px;
  .collapse-text {
    width: 75%;
  }
  .close-button {
    color: $gray;
    position: absolute;
    top: -10px;
    right: -10px;
    cursor: pointer;
  }
  .report-controls {
    .col {
      margin-bottom: 20px;
    }
    .form-label {
      display: block;
    }
    .select-input {
      min-width: 200px;
      max-width: 400px;
    }
    .report-control-buttons {
      align-self: flex-end;
      button {
        height: 38px;
        width: 38px;
      }
      .run-button {
        margin-right: 12px;
      }
    }
  }
  .report-results-wrapper {
    position: relative;
    height: 450px;
    margin: auto;
    background: $gray-light;
    border-radius: 4px;

    .report-results {
      position: relative;
      height: 100%;
      width: 100%;
    }
  }
}
