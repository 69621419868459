@import 'src/common/styles/_colors.scss';


.stat-tip.tooltip.show {
  opacity: 1;
  .tooltip-inner {
    background-color: $almost-black;
    color: $white;
  }
  .arrow {
      color: $almost-black;
  }
}

.stat-panel {
  height: 122px;
  padding: 10px 5px;
  width: 145px;
  border: 1.5px solid $gray-light;
  border-radius: 6px;
  background-color: $white;
  position: relative;
  text-align: center;
  .stat-panel-modal-button, .incident-tip {
    position: absolute;
    bottom: 1px;
    transition: all 0.3s;
    &:hover {
        cursor: pointer;
    }
  }
  .stat-panel-modal-button {
    left: 5px;
    color: $guard-red;
    opacity: 1;
    &:hover {
        opacity: .5;
    }
  }
  .incident-tip {
    right: 5px;
    color: $gray-light;
    &:hover {
        color: $guard-red;
    }
  }
  .stat-panel-title {
    font-size: 10px;
    color: $gray;
    margin-top: 5px;
  }
  .stat-panel-loading {
    font-size: 50px;
    color: $gray-light;
  }
  .stat-panel-value {
    font-size: 50px;
    margin-top: -5px;
  }
  .stat-panel-subvalue-text {
    font-family: 'Lato';
    font-size: 12px;
    margin-top: -10px;
    &.increase, &.decrease, &.equal {
      font-weight: 700;
    }
    &.increase {
      color: $green;
    }
    &.decrease {
      color: $red;
    }
    &.equal {
      color: $orange;
    }
  }
}
