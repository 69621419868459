@import 'src/common/styles/_colors.scss';

.portfolio-container {
    min-height: 62px;
    width:fit-content;
    .portfolio-dropdown {
        padding: 12px;
        background-color: $button-selected-background;
        border-radius: 4px;
        .portfolio-title {
            padding-bottom: 5px;
            color: $white;
            font-size: 20px
        }
        .custom-dropdown {
            width: 280px;
            display: inline-block;
            margin-left: 10px;
            .custom__control {
                border-radius: 5px;
            }
        }
    }
}
@media(max-width: 991px) {
    .portfolio-container {
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 20px;
        .portfolio-dropdown {
            .portfolio-title {
                font-size: 14px;
            }
            .custom-dropdown {
                font-size: 14px;
                width: 250px;
            }
        }
    }
}
