@import 'src/common/styles/_colors.scss';
@import 'src/guarantee_calculator/styles/_variables.scss';

.guarantee-calculator {
  // This is absolutely positioned so that
  // the results of 4 weeks can fit on a single row
  position: absolute;
  top: 200px;
  left: calc(50vw - (#{$guarantee-calculator-width} / 2));
  @media (max-width: $guarantee-calculator-width) {
    left: 0;
  }
  height: calc(100% - 100px);
  overflow: scroll;
  .calculator-inputs {
    display: flex;
    flex-wrap: wrap;
    // unquote being used as workaround
    // https://github.com/sass/libsass/issues/2701
    max-width: unquote("min(100vw, 1285px)");
    @media (max-width: $guarantee-calculator-width) {
      max-width: 100vw;
    }
  }
  .add-button {
    border: 1px dashed $gray;
    border-radius: 5px;
    cursor: pointer;
    text-align: center;
    svg {
      color: $gray;
    }
  }
  .calculator-results {
    margin-top: 20px;
  }
}
