@import 'src/common/styles/_colors.scss';
@import 'src/guarantee_calculator/styles/_variables.scss';


// Repeat colors every 4 weeks. There are 4 weeks per row.
// These colors are taken from the slides that the CS team uses
// to present these results.
.week-results:nth-child(4n+1) {
  margin-top: 60px;
  .results-title .results-banner,.projected-results {
    background-color: #24294F;
  }
}
.week-results:nth-child(4n+2) {
  margin-top: 40px;
  .results-title .results-banner,.projected-results {
    background-color: #4F67A7;
  }
}
.week-results:nth-child(4n+3) {
  margin-top: 20px;
  .results-title .results-banner,.projected-results {
    background-color: #6E9BCC;
  }
}
.week-results:nth-child(4n+4) {
  margin-top: 0px;
  .results-title .results-banner,.projected-results {
    background-color: #407ECF;
  }
}

.calculator-results-wrapper {
  position: relative;

  button.download-button {
    position: absolute;
    top: 20px;
    left: 20px;
  }

  // A width of 1285px allows the results of 4 weeks to fit on a single row
  .calculator-results {
    padding: 25px 50px;
    background-color: $white;
    width: $guarantee-calculator-width;
  }
}

.week-results-row {
  display: flex;
  margin-bottom: 20px;
}

.week-results {
  display: inline-flex;
  flex-direction: column;
  position: relative;
  width: 275px;
  min-height: 200px;
  margin: 5px;
  vertical-align: top;

  .results-title {
    $title-offset: -30px;
    position: relative;
    height: 45px;
    .results-week-number {
      position: absolute;
      top: 0;
      display: inline-block;
      text-align: center;
      color: white;
      padding: 10px;
      width: 100%;
    }
    .results-banner {
      margin-left: $title-offset;
      width: calc(100% - #{$title-offset});
      height: 100%;
    }
    .results-banner-triangle {
      position: absolute;
      bottom: -20px;
      left: $title-offset;
      width: 20px;
      height: 20px;
      border-top: 10px solid $black;
      border-right: 10px solid $black;
      border-bottom: 10px solid transparent;
      border-left: 10px solid transparent;
    }
  }

  .results-activity {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: space-around;
    background-color: $gray-light;
    padding: 20px 10px;

    ul {
      margin: 0;
      padding-left: 25px;
      li {
        padding: 5px 0;
      }
    }
  }

  .projected-results {
    color: $white;
    padding: 0 10px 10px 10px;
    min-height: 50px;
    .projected-results-title {
      margin-top: 10px;
      text-decoration: underline;
      text-align: center;
    }
    .projected-results-subtitle {
      font-size: 12px;
      color: $gray-light;
    }
    .projected-results-result {
      padding-left: 10px;
    }
  }
}
