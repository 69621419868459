@import 'src/common/styles/_colors.scss';


.email-count-panel {
  margin-bottom: 20px;
  .buttons {
    margin-top: -15px;
    .button {
      cursor: pointer;
      display: inline-block;
      height: 15px;
      width: 60px;
      line-height: 15px;
      margin: 0 2px;
      border: none;
      border-radius: 6px;
      background-color: $gray-light;
      &.selected {
        background-color: $guard-red;
        color: $white;
      }
      font-size: 7px;
      color: $black;
    }
  }
}
