@import 'src/common/styles/_colors.scss';


.feedback-button {
  position: absolute;
  bottom: 10px;
  right: 20px;
  .circle-buttons {
    .button {
      background-color: $gray;
      color: $white;
      &:hover {
        background-color: $gray-text-light;
      }
      .fa-comment-alt {
        font-size: 20px;
      }
    }
  }
}
@media(max-width: 576px) {
  .feedback-button {
    bottom: 0px;
    right: 5px;
    .circle-buttons {
      .button {
        width: 40px;
        height: 40px;
        line-height: 35px;
        .fa-comment-alt {
          font-size: 16px;
        }
      }
    }
  }
}

.feedback-modal {
  .modal-title {
    color: $gray-text-light;
  }
  .modal-body {
    color: $almost-black;
  }
  .btn {
    border: none;
    outline: none;
    transition: all 0.2s ease-in-out;
  }
  .submit-feedback {
    background-color: $primary-blue;
    &:hover {
      background-color: $button-highlight-blue;
    }
  }
  .close-feedback {
    background-color: $gray;
    &:hover {
      background-color: $gray-text-light;
    }
  }
}
