@import 'src/common/styles/_colors.scss';


.setting {
  padding: 25px 10px 20px 10px;
  position: relative;
  border: 2px solid $gray-lightest;
  &.setting-changed {
    border: 2px solid $blue;
  }
  border-radius: 25px;
  text-align: center;
  background-color: $gray-lightest;
  .setting-title {
    font-weight: 800;
    font-size: 16px;
    color: $almost-black;
    font-family: 'Lato';
    padding-left: 25px;
    margin-bottom: 0;
    text-align: left;
  }
  .setting-description {
    font-size: 20px;
  }
  .info-icon {
    color: $button-highlight-darkblue;
    position: absolute;
    top: 0px;
    left: 0px;
    font-size: 20px;
    border-radius: 50%;
    background-color: $white;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
    transition: all 0.3s ease-in-out;
    &:hover {
      cursor: pointer;
      color: $blue-dark;
    }
  }
}
@media (max-width: 767px) {
  .setting {
    padding: 20px 10px;
    .setting-title {
      font-size: 14px;
      padding-left: 5px;
      padding-right: 0;
      width: 160px;
    }
  }
}
