@import 'src/common/styles/_colors.scss';

.individual-donor-journey {
  background-color: $gray-light;
  .individual-donor-journey-title {
    font-size: 24px;
    padding: 20px;
    text-align: center;
  }
}
