@import 'src/common/styles/_colors.scss';

.toggle-setting {
  transition: all 0.4s ease-in-out;
  .toggle-input {
    position: relative;
    cursor: pointer;
    background-color: $deactivated-gray;
    height: 50px;
    width: 150px;
    border-radius: 50px;
    display: inline-block;
    margin-left: auto;
    box-shadow: inset 0px 2px 4px rgba(0, 0, 0, .2);
    transition: all 0.3s ease-in-out;
    box-shadow: inset 0px 2.68336px 5.36673px rgba(0, 0, 0, 0.25);
    opacity: .85;
    .toggle {
      position: absolute;
      left: 6px;
      top: 6px;
      background-color: $gray;
      height: 40px;
      width: 40px;
      border-radius: 50%;
      transition: all 0.3s ease-in-out;
      opacity: .9;
    }
    &:hover {
      opacity: 1;
    }
    &.toggle-enabled {
      background-color: $primary-blue;
      .toggle {
        transform: translateX(99px);
        background-color: $white;
      }
    }
  }
  @media(max-width: 767px) {
    .toggle-input {
      width: 100px;
      height: 30px;
      .toggle {
        width: 25px;
        height: 25px;
        left: 3px;
        top: 3px;
      }
      &.toggle-enabled {
        .toggle {
          transform: translateX(69px);
        }
      }
    }
  }
}
