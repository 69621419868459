@import 'src/common/styles/_colors.scss';


.follow-ups-container {
  margin-bottom: 10px;
  .follow-up {
    position: relative;
    border: 1px solid $gray-light;
    border-radius: 5px;
    margin: 0 5px;
    padding: 15px 5px 5px 5px;
    &:focus {
      outline: none;
    }
    .follow-up-remove {
      cursor: pointer;
      position: absolute;
      top: 5px;
      right: 10px;
    }
    input, select {
      display: inline-block;
      margin: 3px;
    }
    input {
      width: 70px;
    }
    select {
      width: 95px;
    }
    &.add-button {
      padding: 10px;
    }
  }
  .follow-up-read-only {
    cursor: pointer;
    margin: 10px 0;
    max-width: 225px;
    // set transparent border so that the hover border doesn't move the div
    border: 1px solid transparent;
    &:hover {
      border: 1px dashed $gray-light;
      border-radius: 5px;
    }
  }
}
