$almost-black: #343434;
$black: #000000;
$blue-light: #D3E2EB;
$blue: #337AB7;
$blue-dark: #384858;
$deactivated-gray: #A6A6A6;
$gray-lightest: #F4F4F4;
$gray-light: #D3D3D3;
$gray: #797979;
$gray-text-light: #ABABAB;
$green: #009339;
$orange: orange;
$red: #AB0000;
$white: #FFFFFF;
$yellow: #FFD44A;

$primary-blue: #015B93;
$delete-red: #D03B2F;

$page-background: #DBE2E7;

$button-background: #DEE3E9;
$button-text: #B0B0B0;
$button-hover-text: $white;
$button-selected-background: #164767;
$button-highlight-blue: #2980b9;
$button-highlight-red: #DB0303;
$button-highlight-darkblue: #526E8A;
$button-selected-text: $button-hover-text;

$guard-red: #931010;
