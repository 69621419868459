@import 'src/common/styles/_colors.scss';


.circle-buttons {
  .button {
    cursor: pointer;
    display: inline-block;
    margin: 20px;
    width: 45px;
    height: 45px;
    text-align: center;
    line-height: 45px;
    font-size: 24px;
    border-radius: 60px;
    box-shadow: 1px 2px 6px rgba(0, 0, 0, .2);
    background-color: $white;
    color: #384858;
    transition: all 0.3s ease-in-out;
    &:hover, &.selected {
      color: $button-hover-text;
    }
    &:hover:not(.selected) {
      background-color: $primary-blue;
    }
    &.selected {
      background-color: $blue-dark;
    }
  }
}
